var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"view_advertisement","size":"xl","title":"View Advertisement","title-class":"font-18"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.closeModal}},[_vm._v("Close")])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.advertisementData)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-sm-6"},[_c('p',{staticClass:"font-size-16 mb-2"},[_c('span',{staticClass:"text-strong"},[_vm._v("Advertisement ID:")]),_vm._v(" "+_vm._s(_vm.advertisementData.placement_id)+" ")]),_c('p',{staticClass:"font-size-16 mb-2"},[_c('span',{staticClass:"text-strong"},[_vm._v("Type:")]),_vm._v(" "+_vm._s(_vm.advertisementData.placement_name)+" ("+_vm._s(_vm.advertisementData.placement_type_short)+") ")]),_c('p',{staticClass:"font-size-16 mb-2"},[_c('span',{staticClass:"text-strong"},[_vm._v("Active: "),_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.format_status(_vm.advertisementData.placement_active ? 'yes' : 'no')
            )}})])]),_c('p',{staticClass:"font-size-16 mb-2"},[_c('span',{staticClass:"text-strong"},[_vm._v("Created:")]),_vm._v(" "+_vm._s(_vm.advertisementData.placement_timestamp)+" ")])]),_c('div',{staticClass:"col-sm-6"},[_c('p',{staticClass:"font-size-16 mb-2 text-strong"},[_vm._v("Advertisement Attributes")]),_vm._l((_vm.advertisementData.attributes),function(value,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(key)+": "+_vm._s(value)+" ")])})],2),_c('hr'),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-sm-12"},[_c('h5',[_vm._v("Advertisement Media")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table mt-3"},[_c('thead',[_c('th',[_vm._v("Media ID")]),_c('th',[_vm._v("Media Type")]),_c('th',[_vm._v("Image")]),_c('th',[_vm._v("Width")]),_c('th',[_vm._v("Height")]),_c('th',[_vm._v("Attributes")]),_c('th',[_vm._v("Text")]),_c('th',[_vm._v("Created")])]),_c('tbody',_vm._l((_vm.advertisementData.media),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_c('div',{staticClass:"flex-shrink-0 "},[_c('a',{attrs:{"title":item.attributes.text,"href":item.source_url,"target":"_blank"}},[_c('img',{staticClass:"img-fluid img-thumbnail table-images",attrs:{"src":item.source_url}})])])]),_c('td',[_vm._v(_vm._s(item.width))]),_c('td',[_vm._v(_vm._s(item.height))]),_c('td',_vm._l((item.attributes),function(value,key){return _c('div',{key:key},[(
                        key != 'width' &&
                          key != 'height' &&
                          key != 'text' &&
                          key != 'source_url'
                      )?_c('span',[_vm._v(_vm._s(key)+": "+_vm._s(value))]):_vm._e()])}),0),_c('td',[_vm._v(_vm._s(item.attributes.text))]),_c('td',[_vm._v(_vm._s(item.timestamp))])])}),0)])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }